import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import {
  Button,
  FormControlLabel,
  IconButton,
  Switch,
  TextField,
  Typography,
  CardContent,
  CardActions,
  Box,
  Grid,
  Container,
  Paper,
} from '@mui/material';
import Agent from '../../api/agent';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import { useNavigate } from 'react-router-dom';
import { socialMedia, authStyles } from './AuthStyle';
import DialogTitle from '@mui/material/DialogTitle';
import MyTextInput from '../Common/Form/MyTextInput';
import * as actions from '../../store/actions/index';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Visibility from '@mui/icons-material/Visibility';
import { motion } from 'framer-motion';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import DialogContentText from '@mui/material/DialogContentText';
import LinearIndeterminate from '../Common/Progress/LinearIndeterminate';
import Logo from '../../assets/images/OPC.jpg';
import axios from 'axios';
import Cookies from 'universal-cookie';
import { GEO_LOCATION_URL, VERIFICATION_COOKIE } from '../../Util/Constants';

const cookies = new Cookies();

const Auth = ({
  onAuthStart,
  onAuthSuccess,
  onSetLocalStorageInfo,
  onAuthFail,
  location,
}) => {
  const history = useNavigate();
  const [screenWidth, setScreenWidth] = useState();
  const verificationCode =
    localStorage.getItem(VERIFICATION_COOKIE) ||
    cookies.get(VERIFICATION_COOKIE) ||
    '';
  const [browserUserInfo, setBrowserUserInfo] = useState({
    ipAddress: '',
    city: '',
    country: '',
    userAgent: navigator.userAgent.toString(),
    verificationCode,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogCode, setOpenDialogCode] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [verificationEmail, setVerificationEmail] = useState();
  const [verificationPhone, setVerificationPhone] = useState();
  const [code, setCode] = useState();
  const [newCode, setNewCode] = useState();
  const [remember, setRemember] = useState(true);
  const [verificationError, setVerificationError] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const getData = async () => {
    try {
      const res = await axios.get(GEO_LOCATION_URL);
      console.log(res.data);
      if (res.status == 200) {
        setBrowserUserInfo({
          ipAddress: res.data.IPv4,
          city: res.data.city,
          country: res.data.country_name,
          userAgent: navigator.userAgent.toString(),
          verificationCode: cookies.get(VERIFICATION_COOKIE),
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setCode(
      localStorage.getItem(VERIFICATION_COOKIE) ||
        cookies.get(VERIFICATION_COOKIE)
    );
    getData();
  }, []);

  useEffect(() => {
    setScreenWidth(
      window.innerWidth ? window.innerWidth : screen.width ? screen.width : 1350
    );
  }, [window.innerWidth, screen.width]);

  const dropShadow = { initial: {} };

  Object.assign(dropShadow.initial, {
    boxShadow: `${
      screenWidth > 900
        ? '0 4px 8px 0 rgba(0, 0, 0, 0.08), 0 6px 20px 0 rgba(0, 0, 0, 0.08)'
        : '0'
    }`,
    marginTop: `${screenWidth > 1350 ? '120px' : '0px'}`,
  });

  const handleSuccessClick = (data) => {
    console.log(data);
  };

  const handleForgotPasswordEmailOnChange = (event) => {
    setForgotPasswordEmail(event.target.value);
  };
  const handleCodeOnChange = (event) => {
    setNewCode(event.target.value);
  };
  const handleClickDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  const handleCodeDialogClose = () => {
    setOpenDialogCode(false);
  };
  const handleForgotPassword = () => {
    Agent.Account.forgotPassword(forgotPasswordEmail)
      .then((response) => {
        handleSuccessClick(response);
        setSuccessMessage(response);
        setSuccessOpen(true); 
      })
      .catch((error) => {
        console.log(error);
      });
    setOpenDialog(false);
  };

  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const handleClickShowPassword = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email')
      .required('Email address is required'),
    password: Yup.string().min(8, 'Must be at least 8 characters').required(),
  });
  const handleCode = async () => {
    onAuthStart();
    await Agent.Account.login2f(email, password, {
      ...browserUserInfo,
      verificationCode: newCode || '',
    })
      .then((response) => {
        if (remember && newCode) {
          cookies.set(VERIFICATION_COOKIE, response.verificationCode, {
            path: '/',
            maxAge: 15552000, // 6 months in seconds
          });
          localStorage.setItem(VERIFICATION_COOKIE, response.verificationCode);
        }
        handleLoginSuccess(response);
        setOpenDialogCode(false);
      })
      .catch((error) => {
        console.log(error);
        setVerificationError(true);
      });
  };

  const handleLoginSuccess = (response) => {
    onSetLocalStorageInfo(response);
    onAuthSuccess(
      response.name,
      response.phoneNumber,
      response.email,
      response.applicationUpdateEmail,
      response.token,
      response.role,
      response.image,
      response.id,
      response.isInternal,
      response.appUserDetailsId
    );
    history(location);
  };

  async function handleFormSubmit(user) {
    onAuthStart();
    await Agent.Account.login(user.email, user.password, browserUserInfo)
      .then((response) => {
        if (response.newDevice) {
          setEmail(user.email);
          setPassword(user.password);
          setVerificationEmail(response.verificationEmailAddress);
          setVerificationPhone(response.verificationPhoneNumber);
          setOpenDialogCode(true);
        } else {
          handleLoginSuccess(response);
        }
      })
      .catch((error) => {
        onAuthFail(error);
        setLoginError(error);
      });
  }
  const successDialogStyles = {
    backgroundColor: 'bg-gray-100',
  };
  let initValues = {
    email: '',
    password: '',
    error: '',
  };
  return (
    <Box sx={authStyles.root}>
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: 'bg-white rounded-lg shadow-xl',
        }}
      >
        <DialogTitle id="success-dialog-title" className="bg-blue-100 text-blue-800 px-6 py-4">Success</DialogTitle>
        <DialogContent className="px-6 py-4">
          <DialogContentText className="text-gray-700">{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions className="px-6 py-4">
          <Button onClick={() => setSuccessOpen(false)} className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Container component="main" maxWidth="xs" sx={authStyles.container}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            className="w-52 h-auto mb-8" 
          />
          <Typography component="h1" variant="h5" align="center" gutterBottom>
            Sign In
          </Typography>
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={initValues}
            onSubmit={(values, { setErrors, setSubmitting }) => {
              handleFormSubmit(values).catch((errors) => {
                setSubmitting(false);
                let err = [];
                if (typeof errors === 'string') {
                  err.push(errors);
                } else {
                  err = errors;
                }
                setErrors({
                  error: err,
                });
              });
            }}
          >
            {({ handleSubmit, isValid, isSubmitting, dirty, errors }) => (
              <Form onSubmit={handleSubmit} autoComplete="off" style={authStyles.formRoot}>
                <MyTextInput
                  name="email"
                  label="Email"
                  placeholder="Email Address"
                  type="email"
                  className="mb-4"
                  InputLabelProps={{ shrink: true, className: "text-gray-600" }}
                  InputProps={{ className: "bg-white rounded-md" }}
                />
                <MyTextInput
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type={passwordVisibility ? 'text' : 'password'}
                  className="mb-4"
                  InputLabelProps={{ shrink: true, className: "text-gray-600" }}
                  InputProps={{
                    className: "bg-white rounded-md",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {passwordVisibility ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                  type="submit"
                  disabled={isSubmitting || !dirty || !isValid}
                  className="w-full py-2 px-4 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-75 transition-colors duration-200"
                >
                  Login
                </button>
                {isSubmitting && <LinearIndeterminate />}
                {loginError && (
                  <Typography color="error" align="center" sx={{ mt: 2 }}>
                    {loginError}
                  </Typography>
                )}
                <Button
                  fullWidth
                  variant="text"
                  onClick={handleClickDialogOpen}
                  sx={authStyles.btn.resetPassword}
                >
                  Reset Password
                </Button>
                <Typography sx={authStyles.signUpText}>
                  Do not have an account? Sign up as a{' '}
                  <Link to="/register/partner" style={authStyles.signUpLink}>
                    Partner
                  </Link>
                </Typography>
                <Box sx={authStyles.socialMedia}>
                  {socialMedia.map((item) => (
                    <Box key={item.id} sx={authStyles.socialIcon}>
                      <motion.a
                        href={item.href}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        {item.icon}
                      </motion.a>
                    </Box>
                  ))}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          className: 'bg-white rounded-lg shadow-xl',
        }}
      >
        <DialogTitle id="form-dialog-title" className="bg-blue-100 text-blue-800 px-6 py-4">Reset Password</DialogTitle>
        <DialogContent className="px-6 py-4">
          <DialogContentText className="text-gray-700 mb-4">
            Enter your email address to reset your password. The email address
            needs to be the one that was used to register the account.
          </DialogContentText>
          <TextField
            margin="dense"
            id="forgotpasswordemail"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleForgotPasswordEmailOnChange}
            InputLabelProps={{
              className: "text-gray-600",
            }}
            InputProps={{
              className: "border border-gray-300 rounded-md p-2 w-full",
            }}
          />
        </DialogContent>
        <DialogActions className="px-6 py-4">
          <Button onClick={handleDialogClose} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded mr-2">
            Cancel
          </Button>
          <Button
            disabled={!forgotPasswordEmail}
            onClick={handleForgotPassword}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
          >
            Reset Password
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDialogCode}
        onClose={handleCodeDialogClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          className: 'bg-white rounded-lg shadow-xl',
        }}
      >
        <DialogTitle id="form-dialog-title" className="bg-blue-100 text-blue-800 px-6 py-4">Device Verification</DialogTitle>
        <DialogContent className="px-6 py-4">
          <DialogContentText className="text-gray-700 mb-4">
            {`Enter the verification code sent to the email address: ${verificationEmail} and
            press Submit`}
          </DialogContentText>
          <TextField
            size="small"
            margin="dense"
            id="forgotpasswordemail"
            label="2 Factor Code"
            type="text"
            fullWidth
            onChange={handleCodeOnChange}
            InputLabelProps={{
              className: "text-gray-600",
            }}
            InputProps={{
              className: "border border-gray-300 rounded-md p-2 w-full",
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={remember}
                onChange={(e) => setRemember(e.target.checked)}
                name="Status"
                className="text-blue-600"
              />
            }
            label={
              remember
                ? 'This device will be remembered by the system'
                : 'Click to remember this device'
            }
            className="mt-4 text-gray-700"
          />
        </DialogContent>
        <DialogActions className="px-6 py-4">
          <Grid container direction="column" spacing={2}>
            {verificationError && (
              <Grid item>
                <Typography className="text-red-500">
                  Invalid verification code
                </Typography>
              </Grid>
            )}
            <Grid item container justifyContent="space-between">
              <Grid item>
                <Button onClick={handleCodeDialogClose} className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!newCode}
                  onClick={handleCode}
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    location: ownProps ? ownProps.location : null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthStart: () => dispatch(actions.authStart()),
    onAuthSuccess: (
      name,
      phoneNumber,
      email,
      applicationUpdateEmail,
      token,
      role,
      image,
      id,
      isInternal,
      appUserDetailsId
    ) =>
      dispatch(
        actions.authSuccess(
          name,
          phoneNumber,
          email,
          applicationUpdateEmail,
          token,
          role,
          image,
          id,
          isInternal,
          appUserDetailsId
        )
      ),
    onSetLocalStorageInfo: (response) => actions.setLocalStorageInfo(response),
    onAuthFail: (error) => dispatch(actions.authFail(error)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);

