/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Tab,
  Tabs,
  CircularProgress,
  Fab,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import TelegramIcon from '@mui/icons-material/Telegram';
import DoneIcon from '@mui/icons-material/Done';
import { connect } from 'react-redux';
import Chat from '../../Common/Chat/chat';
import LinearIndeterminate from '../../Common/Progress/LinearIndeterminate';
import MySnackbarAlert from '../../Common/AlertMessage/MySnackbarAlert';
import ApplicationStage from './Stages/Applications/ApplicationStage';
import ConfirmationModal from '../../Common/Modal/ConfirmationModal';
import StudentContext from '../../Students/Context/student-context';
import StudentProfile from './Stages/StudentProfile/StudentProfile';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LoadingView from '../../Common/Loading/LoadingView';
import { AGENT, STUDENT } from '../../../Util/Constants';
import SummaryStage from './Stages/Summary/SummaryStage';
import UserContext from '../../../context/user-context';
import Education from './Stages/Education/Education';
import Documents from './Stages/Documents/Documents';
import MyTabLabel from '../../Common/Tab/MyTabLabel';
import MyTabLabelMob from '../../Common/Tab/MyTabLabelMob';
import Preferences from './Preferences/Preferences';
import { newStudent } from '../Constant/Constants';
import AddMessage from '../../Messages/AddMessage';
import Messages from './Stages/Messages/Messages';
import Language from './Stages/Language/Language';
import { useLocation, useParams } from 'react-router';
import Summary from './Summary/Summary';
import Agent from '../../../api/agent';
import Tasks from '../../Tasks/Tasks';
import Task from '../../Tasks/Task';
import Steppers from './Steppers';

import { HiMenuAlt3 } from 'react-icons/hi';
import StudentFooter from './Footer/StudentFooter';
import useWindowSize from '../common/useWindowSize';
import StudentFooterMob from './Footer/StudentFooterMob';
import { Typography } from '@mui/material';

import { Countries } from '../../../Util/Util';
import Card from '../../Custom/Card/Card';
import { CardBody } from '@material-tailwind/react';
import moment from 'moment/moment';
import { isNull } from 'lodash';
import {
  AssignmentOutlined,
  ChatRounded,
  ContactsRounded,
  CurrencyExchange,
  Description,
  LanguageRounded,
  PersonSearchRounded,
  School,
  SecurityRounded,
  SummarizeRounded,
  TaskRounded,
} from '@mui/icons-material';
import Email from '@mui/icons-material/Email';
import AccountBalance from '@mui/icons-material/AccountBalance';
import StudentActivites from '../../UserActivity/StudentActivity/StudentActivites';
import StudentAccountsNote from './StudentAccountsNote/StudentAccountsNote';
import StudentContactInfo from './Stages/ContactInfo/StudentContactInfo';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} style={{ paddingTop: 0 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const formattedDate = (date) => {
  return moment(date).format('MMMM D, YYYY');
};

const Student = ({
  appUserId,
  role,
  roleName,
  isInternal,
  name,
  props,
  permissions,
}) => {
  const navigate = useNavigate();
  const { id, applicationid } = useParams();
  const [studentId, setStudentId] = useState(0);
  let studentContext = useContext(StudentContext);
  const userContext = useContext(UserContext);
  const [focused, setFocused] = useState(false);

  const [activeStep, setActiveStep] = useState(1);
  const [completed, setCompleted] = useState(new Set([0]));

  const [profile, setProfile] = useState({});
  const [eduRecords, setEduRecords] = useState([]);
  const [langRecords, setLangRecords] = useState([]);
  const [cloudFiles, setCloudFiles] = useState([]);
  const [applications, setApplications] = useState([]);
  const [studentPhoto, setStudentPhoto] = useState();

  const [accountsNotes, setStudentAccountsNote] = useState([]);
  const [notes, setNotes] = useState([]);

  const [loaded, setLoaded] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [profileError, setProfileError] = useState(false);
  const [contactError, setContactError] = useState(false);
  const [contactValueNoChange, setContactValueNoChange] = useState(false);
  const [eduError, setEduError] = useState(false);
  const [docError, setDocError] = useState(false);
  const [termsAgreed, setTermsAgreed] = useState(0);
  const [termsAgreedTemp, setTermsAgreedTemp] = useState(0);
  const [tempImage, setTempImage] = useState();
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab');
  const [tabValue, setTabValue] = useState(
    applicationid ? 'app' : tab ? tab : 'profile'
  );
  // console.log('tabValue', tabValue);
  const [openAddMessage, setOpenAddMessage] = useState(false);
  const [open, setOpen] = useState(true);
  //#snackbar stuff
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [alertMsg, setAlertMsg] = useState();
  //#endregion

  const [openConfirm, setOpenConfirm] = useState(false);
  const [errors, setErrors] = useState([]);
  const [inValidEmail, setInValidEmail] = useState(false);

  const [agentName, setAgentName] = useState();
  const [draftMessage, setDraftMessage] = useState('');
  const [addTask, setAddTask] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successOpen, setSuccessOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const { agentid } = useParams();
  const [referralName, setReferralName] = useState();
  const location = useLocation();
  const urlname = location.pathname.split('/');
  const referralId = urlname[4];
  const successDialogStyles = {
    backgroundColor: 'bg-green-500',
  };
  const [studentLeadId, setStudentLeadId] = useState();
  const [formData, setFormData] = useState({
    Email: profile.email,
    PhoneNumber: profile.phoneNumber,
    AdditionalEmail1: profile.additionalEmail1,
    AdditionalEmail2: profile.additionalEmail2,
    AdditionalPhoneNumber: profile.additionalPhoneNumber,
    Country: profile.country,
    StreetAddress: profile.streetAddress,
  });
  const [updateSuccess, setUpdateSuccess] = useState(false);

  // Custom styles for error dialog
  const errorDialogStyles = {
    backgroundColor: 'bg-red-400',
  };
  var agentAppUserId = 0;
  var createdByAppUserId = 0;
  if (roleName === AGENT) {
    agentAppUserId = appUserId; // this means agent is managing their own students
  } else if (roleName === STUDENT) {
    createdByAppUserId = 0; // here, app user will be determined by the backend code from logged in user
  } else {
    createdByAppUserId = appUserId; // this means, admin managing student
  }
  studentContext.agentAppUserId = agentAppUserId;
  studentContext.createdByAppUserId = createdByAppUserId;

  useEffect(() => {
    if (urlname[3] == 'referraladdstudent') {
      Agent.Referral.getreferralbyRefId(referralId).then((ref) => {
        console.log('ref', ref);
        setReferralName(ref.firstName + ' ' + ref.lastName);
      });
    } else {
      if (agentid) {
        Agent.Agents.getAgent(agentid).then((agent) => {
          console.log('agent', agent);
          setAgentName(agent.companyName);
        });
      }
    }
  }, []);

  useEffect(() => {
    if (profile.id) {
      Agent.Students.getStudentLeadByStuId(parseInt(profile.id)).then((res) => {
        setStudentLeadId(res.id);
      });
    }
  }, [profile.id]);

  useEffect(() => {
    if (id || role.isStudent) {
      var idToUse = id || 0;
      Agent.Students.getStudent(idToUse)
        .then((std) => {
          console.log('student profile response', profile.isDirect);

          const {
            educationRecords,
            languageRecords,
            studentCloudFiles,
            applications,
            studentNotes,
            accountsNotes,
            ...rest
          } = std;
          setStudentId(rest.id);
          setProfile(rest);
          setEduRecords(educationRecords);
          setTermsAgreed(std.termsConditionsAgreed);
          console.log(std.termsConditionsAgreed);
          setTermsAgreedTemp('check std terms', std.termsConditionsAgreed);
          setLangRecords(languageRecords);
          setApplications(applications);

          accountsNotes?.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });
          setStudentAccountsNote(accountsNotes);

          studentNotes.sort(function (a, b) {
            return new Date(b.dateSent) - new Date(a.dateSent);
          });

          setNotes(studentNotes);

          setCloudFiles(
            studentCloudFiles.filter((file) => file.documentType != 16)
          );
          setStudentPhoto(
            studentCloudFiles.filter((file) => file.documentType == 16)[0]
          );
          setFormData({
            Email: rest.email,
            PhoneNumber: rest.phoneNumber,
            AdditionalEmail1: rest.additionalEmail1,
            AdditionalEmail2: rest.additionalEmail2,
            AdditionalPhoneNumber: rest.additionalPhoneNumber,
            Country: rest.country,
            StreetAddress: rest.streetAddress,
          });
          setLoaded(true);
        })
        .catch((error) => {
          console.log(error);
          debugger;
        });
    } else {
      setProfile(newStudent.profile);
      setEduRecords(newStudent.educationRecords);
      setLangRecords(newStudent.languageRecords);
      setLoaded(true);
    }
    setReload(false);
  }, [id, reload]);
  const setStudentIdParent = (id) => {
    setProfile({ ...profile, id });
    setStudentId(id);
  };

  const updateProfileParent = (child) => {
    setFocused(true);
    setProfile({ ...profile, ...child });
  };

  const uploadTempImageToCloud = async (id) => {
    Agent.CloudFiles.upload(tempImage, 16, 0, id)
      .then((response) => {
        var file = {
          fileUrl: response.blobUri,
          fileName: response.blobName,
          fileNameLocal: response.fileNameLocal,
          documentType: 16,
        };
        setStudentPhoto(file);
        setTempImage(null);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleUpdateInfo = () => {
    setUpdateSuccess(false);
    setContactValueNoChange(false);
    const payload = {
      Id: profile.id,
      Email: formData?.Email === profile.email ? null : formData?.Email,
      PhoneNumber:
        formData.PhoneNumber === profile.phoneNumber
          ? null
          : formData.PhoneNumber,
      AdditionalEmail1:
        formData.AdditionalEmail1 === profile.additionalEmail1
          ? null
          : formData.AdditionalEmail1,
      AdditionalEmail2:
        formData.AdditionalEmail2 === profile.additionalEmail2
          ? null
          : formData.AdditionalEmail2,
      AdditionalPhoneNumber:
        formData.AdditionalPhoneNumber === profile.additionalPhoneNumber
          ? null
          : formData.AdditionalPhoneNumber,
      Country: formData.Country === profile.country ? 0 : formData.Country,
      StreetAddress:
        formData.StreetAddress === profile.streetAddress
          ? null
          : formData.StreetAddress,
    };

    if (
      payload.Email == null &&
      payload.PhoneNumber == null &&
      payload.AdditionalEmail1 == null &&
      payload.AdditionalEmail2 == null &&
      payload.AdditionalPhoneNumber == null &&
      payload.Country == 0 &&
      payload.StreetAddress == null
    ) {
      setContactValueNoChange(true);
    } else {
      Agent.Students.updateStudentContactInfo(payload)
        .then((response) => {
          console.log('Update successful', response);
          setUpdateSuccess(true);
          setProfile({ ...profile, ...formData });
          //fetchData();
        })
        .catch((error) => {
          console.error('Update failed', error);
          //setUpdateError(`Failed to update information: ${error}`);
        });
    }
  };

  const handleSave = (complte = false) => {
    if (inValidEmail) return;
    setProfileError(false);
    setContactError(false);
    setEduError(false);
    setDocError(false);
    setTermsAgreedTemp(termsAgreed);
    var studentData = {
      saveStudentDto: {
        student: {
          ...profile,
          agentId: agentid, // when agent is is passed by admin on behalf of the agent
          referralId:
            urlname[3] == 'referraladdstudent'
              ? referralId
              : profile.referralId,
          educationRecords: eduRecords,
          languageRecords: langRecords,
          TermsConditionsAgreed: termsAgreed,
        },
        // agentAppUserId: studentContext.agentAppUserId,
        // createdByAppUserId: studentContext.createdByAppUserId,
      },
    };
    if (complte) {
      setSubmitting(true);
      studentData.saveStudentDto.student = {
        ...studentData.saveStudentDto.student,
        ...{ profileComplete: true, profileCompletedOn: new Date() },
      };
    } else {
      setUpdating(true);
    }

    Agent.Students.saveStudent(studentData)
      .then((id) => {
        setFocused(false);
        studentContext.profile = {
          ...studentContext.profile,
          id: id,
        };
        setProfile({
          ...profile,
          id: id,
        });
        setStudentId(id);
        if (tempImage) {
          uploadTempImageToCloud(id)
            .then(setAlert)
            .catch((error) => {
              console.log(error);
              setAlert('Error: Failed to save the image. Try again!', false);
              debugger;
            });
        } else {
          setAlert();
          if (complte) {
            navigate(`/students/${id}/dashboard`);
          }
        }
      })
      .catch((error) => {
        console.log('error save', error);
        if (error.includes('Passport')) {
          setAlert(
            'This student file is already registered in the system. Please send an email from the CRM section or email support at support@oldportsmouthcollge.com.',
            false
          );
        } else if (
          error.includes('The Email field is not a valid e-mail address.')
        ) {
          setAlert('The Email field is not a valid e-mail address.', false);
        } else if (error.includes('Check first name and last name')) {
          setAlert('Please, Check The first name and last name Fields', false);
        } else {
          setAlert(
            'Error: Failed to save. Please check the length of all the input fields',
            false
          );
        }

        // we will still try to upload the temp image. sometimes users might just change the image and nothign else.
        // if there is no other data changed, student save will fail but thats kind of ok. we will still want to upload the file
        // if (profile.id && tempImage) {
        //   uploadTempImageToCloud(profile.id)
        //     .then(setAlert)
        //     .catch((error) => {
        //       console.log(error);
        //       setAlert('Error: Failed to save the image. Try again!', false);
        //     });
        // } else {
        //   setAlert();
        // }
      });
  };

  const setAlert = (msg = 'Successfully Save', isSuccess = true) => {
    setUpdating(false);
    setSubmitting(false);
    //setAlertMsg(msg);
    if (isSuccess) {
      setSuccessMessage(msg);
      setSuccessOpen(true);
    } else {
      setErrorMessage(msg);
    }
    setOpenSuccess(isSuccess);
    setOpenFailure(!isSuccess);
  };

  const submitProfile = () => {
    setOpenConfirm(false);
    //otherwise go ahead and submit the form
    handleSave(true);
  };

  const handleSubmit = () => {
    let isValid = true;
    setProfileError(false);
    setEduError(false);
    setDocError(false);
    setErrors([]);
    let tempErrors = [];
    if (!termsAgreedTemp) {
      setAlert(
        'Please verify the "Terms and Conditions" checkbox and click on Save before submitting.',
        false
      );
      return;
    } else {
      if (role.isStudent || role.isAgent) {
        if (
          profile.firstName.length == 0 ||
          profile.lastName.length == 0 ||
          !profile.passportNumber ||
          profile.passportNumber.length == 0 ||
          profile.dateOfBirth == null ||
          !profile.gender ||
          profile.gender == 0 ||
          !profile.maritalStatus ||
          profile.maritalStatus == 0 ||
          !profile.citizenship ||
          profile.citizenship == 0 ||
          !profile.placeOfBirth ||
          profile.placeOfBirth.length == 0 ||
          !profile.countryOfResidence ||
          profile.countryOfResidence == 0 ||
          !profile.countryOfIssue ||
          profile.countryOfIssue == 0 ||
          !profile.email ||
          profile.email.length == 0 ||
          !profile.phoneNumber ||
          profile.phoneNumber.length == 0 ||
          !profile.streetAddress ||
          profile.streetAddress.length == 0 ||
          !profile.country ||
          profile.country == 0
        ) {
          setProfileError(true);
          isValid = false;
          tempErrors.push('Missing fields in General Info section');
        }
      } else {
        if (
          profile.firstName.length == 0 ||
          profile.lastName.length == 0 ||
          !profile.passportNumber ||
          profile.passportNumber.length == 0 ||
          profile.dateOfBirth == null ||
          !profile.gender ||
          profile.gender == 0 ||
          !profile.maritalStatus ||
          profile.maritalStatus == 0 ||
          !profile.citizenship ||
          profile.citizenship == 0 ||
          !profile.placeOfBirth ||
          profile.placeOfBirth.length == 0 ||
          !profile.countryOfResidence ||
          profile.countryOfResidence == 0 ||
          !profile.countryOfIssue ||
          profile.countryOfIssue == 0
        ) {
          setProfileError(true);
          isValid = false;
          tempErrors.push('Missing fields in General Info section');
        }
        if (
          !formData.Email ||
          !formData.PhoneNumber ||
          !formData.StreetAddress ||
          formData.Country == 0
        ) {
          isValid = false;
          tempErrors.push('Missing fields in Contact Info section');
          setContactError(true);
        } else {
          setContactError(false);
        }
      }

      if (eduRecords.length == 0) {
        setEduError(true);
        isValid = false;
        tempErrors.push('Education record missing in Education section');
      }
      if (cloudFiles.filter((f) => f.documentType == 2).length == 0) {
        setDocError(true);
        isValid = false;
        tempErrors.push(
          'Document (passport copy) record missing in Documents section'
        );
      }

      setErrors(tempErrors);
      setOpenConfirm(true);
      if (!isValid) return;
    }
  };

  const setTab = (tabIndex, stepperIndex) => {
    setTabValue(tabIndex);
    if (stepperIndex) {
      setActiveStep(stepperIndex);
    }
  };

  const apply = () => {
    userContext.setUserContext({
      ...userContext,
      ...{
        applicationId: 0,
        studentId: parseInt(studentId),
        studentName: `${profile.firstName} ${profile.lastName}`,
      },
    });
    navigate(`/programs/${studentId}`);
  };
  // const [focused, setFocused] = useState(false);
  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
    // setShow(true);
  };
  useEffect(() => {
    //handleTabChange('profile');
    const handleBeforeUnload = (event) => {
      if (focused) {
        event.preventDefault();
        event.returnValue =
          'Are you sure you want to leave? Any unsaved data will be lost';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [focused]);

  // NOTE: Below code is commented out because it is not used. If used in the future, remove this comment.

  // const handleNavigate = (path) => {
  //   if (focused) {
  //     const confirmed = window.confirm(
  //       'Are you sure you want to leave? Any unsaved data will be lost'
  //     );
  //     if (confirmed) {
  //       setFocused(false);
  //       navigate(path);
  //     }
  //   } else {
  //     navigate(path);
  //   }
  // };

  const size = useWindowSize();
  return (
    <>
      {' '}
      <Dialog
        open={successOpen}
        onClose={() => setSuccessOpen(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>{successMessage}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* Show error Dialog */}
      <Dialog
        open={!!errorMessage}
        onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: 'bg-gray-200',
        }}
      >
        <DialogTitle id="error-dialog-title" className="text-red-700">
          Error
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-red-700">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setErrorMessage('')}
            color="primary"
            className="text-red-700"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* warning */}
      <Dialog
        open={!!contactValueNoChange}
        //onClose={() => setErrorMessage('')}
        aria-labelledby="error-dialog-title"
        PaperProps={{
          className: 'bg-gray-200',
        }}
      >
        <DialogTitle id="error-dialog-title" className="text-blue-500">
          warning!!
        </DialogTitle>
        <DialogContent>
          <DialogContentText className="text-gray-900 text-lg">
            {contactValueNoChange && (
              <strong className="text-blue-500">No changes found!!</strong>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => setContactValueNoChange(false)}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {/* update message for contact info  */}
      <Dialog
        open={updateSuccess}
        onClose={() => setUpdateSuccess(false)}
        aria-labelledby="success-dialog-title"
        PaperProps={{
          className: successDialogStyles.backgroundColor, // Apply successDialogStyles here
        }}
      >
        <DialogTitle id="success-dialog-title">Success</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {updateSuccess && (
              <strong className="text-lg">Update Successfully</strong>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setUpdateSuccess(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      {size.width <= 844 ? (
        <>
          {' '}
          {!loaded ? (
            <div className="w-full max-w-[1400px] pt-6">
              <br />
              <br />
              <LoadingView />
              <br />
              <br />
              <br />
              <br />
              <br />
              <LoadingView />
              <br />
              <br /> <br />
              <br />
            </div>
          ) : (
            <div className="flex items-top py-2 px-0 min-h-screen  min-w-screen p-0 bg-gray-900">
              <div className="flex flex-col w-full h-full relative">
                <div className="relative bg-gray-700 mb-2 rounded">
                  <Card className="flex flex-col w-full h-full relative my-2 bg-white">
                    <CardBody>
                      <Typography className="mb-2 text-white text-xl uppercase">
                        {profile.firstName + ' ' + profile.lastName}
                      </Typography>
                      <Typography className="mb-2 text-blue-200">
                        <ul>ID:{' ' + profile.id}</ul>
                        <ul>
                          Email:{' ' + profile.email ? profile.email : ''}
                        </ul>
                        <ul>
                          Phone:
                          {' ' + profile.phoneNumber ? profile.phoneNumber : ''}
                        </ul>

                        <ul>
                          Country:
                          {isNull(profile.country)
                            ? ''
                            : Countries.find(({ id }) => id === profile.country)
                                ?.name}
                        </ul>
                        <ul>
                          Birth Date:
                          {isNull(profile.dateOfBirth)
                            ? ''
                            : formattedDate(profile.dateOfBirth)}
                        </ul>
                      </Typography>
                      <Typography className="pt-0">
                        <div>
                          {/* <Button
                          onClick={apply}
                          className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                          disabled={!isInternal && applications.length > 2}
                        >
                          Apply FOR COURSE
                        </Button> */}
                          {!role.isAgent && !role.isStudent && (
                            <div>
                              <Button
                                onClick={() => setAddTask(true)}
                                startIcon={<AssignmentIcon />}
                                className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                              >
                                Create Task
                              </Button>
                            </div>
                          )}
                          <div>
                            {profile.profileComplete == true && (
                              <Button
                                onClick={apply}
                                className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                                disabled={
                                  !isInternal && applications.length > 5
                                }
                              >
                                Apply FOR COURSE
                              </Button>
                            )}
                          </div>
                          <br />
                          <div>
                            <Button
                              onClick={() =>
                                navigate(`/students/${studentId}/dashboard`)
                              }
                              className="bg-indigo-100 hover:bg-indigo-100 hover:text-gray-800 text-gray-800"
                            >
                              Go to Student Dashboard
                            </Button>
                          </div>
                        </div>
                      </Typography>
                    </CardBody>
                  </Card>
                </div>

                <div className="mt-0 flex gap-4 relative bg-gray-700">
                  <Tabs
                    // TabIndicatorProps={{ style: { display: 'none' } }}
                    value={tabValue}
                    onChange={handleTabChange}
                    orientation="horizontal"
                    variant="scrollable"
                    aria-label="Vertical tabs"
                    className=" flex text-sm justify-start items-start text-left"
                  >
                    <Tab
                      value="profile"
                      style={{ textAlign: 'left' }}
                      {...a11yProps('profile')}
                      label={
                        <MyTabLabelMob
                          label="General Info"
                          showWarning={profileError}
                        />
                      }
                    />
                    <Tab
                      value="edu"
                      style={{ textAlign: 'left' }}
                      {...a11yProps('edu')}
                      label={
                        <MyTabLabelMob
                          label={`Education ${
                            eduRecords.length > 0
                              ? ' (' + eduRecords.length + ')'
                              : ''
                          }`}
                          showWarning={eduError}
                        />
                      }
                    />
                    <Tab
                      value="lang"
                      style={{ textAlign: 'left' }}
                      {...a11yProps('lang')}
                      label={
                        <MyTabLabelMob
                          label={`Language ${
                            langRecords.length > 0
                              ? ' (' + langRecords.length + ')'
                              : ''
                          }`}
                          showWarning={false}
                        />
                      }
                    />
                    <Tab
                      value="doc"
                      style={{ textAlign: 'left' }}
                      {...a11yProps('doc')}
                      label={
                        <MyTabLabelMob
                          label={`Documents ${
                            cloudFiles.length > 0
                              ? ' (' + cloudFiles.length + ')'
                              : ''
                          }`}
                          showWarning={docError}
                        />
                      }
                    />
                    <Tab
                      style={{ textAlign: 'left' }}
                      className="w-64 "
                      value="pref"
                      {...a11yProps('pref')}
                      label={
                        <MyTabLabelMob
                          label="Study Preferences"
                          showWarning={false}
                        />
                      }
                    />
                    {profile.id > 0 && (
                      <Tab
                        value="msg"
                        style={{ textAlign: 'left' }}
                        {...a11yProps('msg')}
                        label={
                          <MyTabLabelMob label="Email" showWarning={false} />
                        }
                      />
                    )}
                    {applications.length > 0 && (
                      <Tab
                        style={{ textAlign: 'left' }}
                        value="app"
                        {...a11yProps('app')}
                        label={
                          <MyTabLabelMob
                            label={`Applications (${applications.length})`}
                            showWarning={false}
                          />
                        }
                      />
                    )}
                    {permissions.CanManageStudentChatSection && (
                      <Tab
                        style={{ textAlign: 'left' }}
                        value="chat"
                        {...a11yProps('chat')}
                        label={
                          <MyTabLabelMob label="Chats" showWarning={false} />
                        }
                      />
                    )}
                  </Tabs>
                </div>

                <div className="w-full rounded-lg">
                  <TabPanel
                    value={tabValue}
                    index={'profile'}
                    style={{ paddingTop: 0 }}
                  >
                    <StudentProfile
                      profile={profile}
                      displayError={profileError}
                      updateProfileParent={updateProfileParent}
                      studentPhoto={studentPhoto}
                      setStudentPhoto={setStudentPhoto}
                      tempImageParent={tempImage}
                      setTempImageParent={setTempImage}
                      inValidEmail={inValidEmail}
                      setInValidEmail={setInValidEmail}
                      name={name}
                      role={role}
                      setTermsAgreed={setTermsAgreed}
                      termsAgreed={termsAgreed}
                      isInternal={isInternal}
                    />
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={'edu'}
                    style={{ marginTop: 15 }}
                  >
                    <Education
                      id={profile.id}
                      setStudentIdParent={setStudentIdParent}
                      records={eduRecords}
                      displayError={eduError}
                      setRecordsGrandParent={setEduRecords}
                      isLocked={role.isStudent && profile.profileComplete}
                    />
                  </TabPanel>
                  <TabPanel
                    value={tabValue}
                    index={'lang'}
                    style={{ marginTop: 15 }}
                  >
                    <Language
                      id={profile.id}
                      setStudentIdParent={setStudentIdParent}
                      records={langRecords}
                      setRecordsGrandParent={setLangRecords}
                      isLocked={role.isStudent && profile.profileComplete}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={'doc'}>
                    <Documents
                      id={profile.id}
                      setStudentIdParent={setStudentIdParent}
                      appUserId={appUserId}
                      roleName={roleName}
                      cloudFiles={cloudFiles}
                      displayError={docError}
                      setCloudFiles={setCloudFiles}
                      isLocked={role.isStudent && profile.profileComplete}
                      isInternal={isInternal}
                      permissions={permissions}
                    />
                  </TabPanel>
                  <TabPanel value={tabValue} index={'pref'}>
                    <div>
                      <Preferences id={profile.id || 0} />
                    </div>
                  </TabPanel>
                  {/* {profile.agentName && ( */}
                  <TabPanel value={tabValue} index={'msg'}>
                    <Messages
                      studentId={profile.id}
                      studentName={`${profile.firstName} ${profile.lastName}`}
                      name={name}
                      isInternal={isInternal}
                      records={notes}
                      setRecords={setNotes}
                      draftMessageParent={draftMessage}
                      setDraftMessageParent={setDraftMessage}
                      agentName={profile?.agentName}
                      role={role}
                      isDirect={profile?.isDirect}
                    />
                  </TabPanel>
                  {applications.length > 0 && (
                    <TabPanel value={tabValue} index={'app'}>
                      <ApplicationStage
                        records={applications}
                        applicationToExpand={applicationid ? applicationid : 0}
                        isLocked={!isInternal && profile.profileComplete}
                      />
                    </TabPanel>
                  )}
                  {isInternal &&
                    (!profile.agentName || profile.agentName.length == 0) && (
                      <TabPanel value={tabValue} index={'chat'}>
                        <Chat
                          recordId={profile.id}
                          recipientName={`${profile.firstName} ${profile.lastName}`}
                          name={name}
                          phone={profile.phoneNumber ? profile.phoneNumber : ''}
                          appUserId={appUserId}
                          type="student"
                          country={
                            profile.countryOfResidence ||
                            profile.citizenship ||
                            profile.country
                          }
                        />
                      </TabPanel>
                    )}

                  {isInternal && (
                    <TabPanel value={tabValue} index={'activities'}>
                      <StudentActivites studentId={profile.id} />
                    </TabPanel>
                  )}

                  {isInternal && (
                    <TabPanel value={tabValue} index={'accountsnote'}>
                      <StudentAccountsNote
                        records={accountsNotes}
                        setRecords={setStudentAccountsNote}
                        studentId={profile.id}
                        name={name}
                      />
                    </TabPanel>
                  )}
                </div>
                <div className="flex justify-end pt-3 pb-6">
                  {tabValue == 'profile' &&
                    loaded &&
                    (!profile.profileComplete || isInternal) && (
                      <Fab
                        size="small"
                        variant="extended"
                        className="bg-sky-500 hover:bg-sky-6sendChat00 text-white"
                        style={{ marginRight: 10 }}
                        onClick={() => handleSave(false)}
                        disabled={
                          profile.firstName.trim().length == 0 ||
                          profile.lastName.trim().length == 0 ||
                          updating ||
                          inValidEmail
                        }
                      >
                        <DoneIcon />
                        {updating && (
                          <>
                            <CircularProgress size={14} />
                            <span style={{ marginLeft: 5 }}>Saving</span>
                          </>
                        )}
                        {!updating && 'Save'}
                      </Fab>
                    )}
                  {(tabValue == 'profile' ||
                    tabValue == 'edu' ||
                    tabValue == 'lang' ||
                    tabValue == 'doc') &&
                    loaded &&
                    !profile.profileComplete && (
                      <Fab
                        size="small"
                        variant="extended"
                        className="bg-violet-500 hover:bg-violet-600 text-white"
                        disabled={
                          profile.firstName.trim().length == 0 ||
                          profile.lastName.trim().length == 0 ||
                          updating ||
                          inValidEmail
                        }
                        onClick={handleSubmit}
                      >
                        <TelegramIcon />
                        {submitting && (
                          <>
                            <CircularProgress size={14} color="secondary" />
                            <span style={{ marginLeft: 5 }}>Submitting</span>
                          </>
                        )}
                        {!submitting && 'Submit'}
                      </Fab>
                    )}
                </div>

                {(openSuccess || openFailure) && (
                  <MySnackbarAlert
                    opnSuccess={openSuccess}
                    opnFailure={openFailure}
                    message={alertMsg}
                    opnSuccessParent={setOpenSuccess}
                    opnFailureParent={setOpenFailure}
                  />
                )}

                <ConfirmationModal
                  open={openConfirm}
                  cancel={setOpenConfirm}
                  confirm={submitProfile}
                  confirmText="Confirm Submission"
                  content="Are you sure that you would like to submit the student profile?"
                  errors={errors}
                />
                {openAddMessage && (
                  <AddMessage
                    open={openAddMessage}
                    cancel={setOpenAddMessage}
                    toId={profile.appUserId}
                    agentId={0}
                    toName={`${profile.firstName} ${profile.lastName}`}
                  />
                )}

                {addTask && (
                  <Task
                    record={{ id: 0, studentId: profile.id }}
                    setAddMode={setAddTask}
                    taskFor={`${profile.firstName} ${profile.lastName}`}
                    cosmosAdminUsersParent={[]}
                    path={1}
                    tabToReturnTo={'tasks'}
                  />
                )}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="flex items-center min-h-screen  min-w-screen p-0 md:justify-center pt-0 md:pl-0 bg-white">
          <section className="flex pt-64 items-center sm:justify-center h-full w-full sm:overflow-scroll">
            <div className="container mx-auto px-0">
              <div className="relative flex flex-col min-w-0 break-words bg-sky-800 w-full mb-6 shadow-xl rounded-lg -mt-64">
                <div className="justify-between bg-white items-top pt-8  lg:flex">
                  <div className="relative justify-center items-center p-0 h-full w-full lg:pr-4">
                    <div className="w-full flex justify-center ">
                      {/*Prompt is no longer supported in React Dom V6. Need to look this up and replace with the updated way in react dom v6  */}
                      {/* <Prompt
                        when={focused}
                        message={() =>
                          'Are you sure you want to leave? Any unsaved data will be lost'
                        }
                      /> */}
                      {!loaded && (
                        <div className="w-full max-w-[1400px] pt-6">
                          <LoadingView />
                        </div>
                      )}
                      {loaded && (
                        <div className="w-full max-w-[1400px] pt-6">
                          {updating && <LinearIndeterminate />}
                          {profile.profileComplete == false ? (
                            <>
                              <div>
                                <Steppers
                                  activeStep={activeStep}
                                  setActiveStep={setActiveStep}
                                  completed={completed}
                                  setCompleted={setCompleted}
                                  setTab={setTab}
                                />
                                <div className="flex gap-3 justify-end pr-2">
                                  {studentLeadId &&
                                  isInternal &&
                                  !role.isStudent ? (
                                    <div className="text-sky-800 text-xl font-medium drop-shadow-">
                                      <Button className="bg-gradient-to-r from-cyan-500 via-cyan-700 to-cyan-800 mb-2 hover:bg-cyan-600 text-gray-100 px-4 drop-shadow-md hover:shadow-md">
                                        {
                                          <Link
                                            to={`/leads/${studentLeadId}/leadprofile`}
                                          >
                                            Go to Lead Profile
                                          </Link>
                                        }
                                      </Button>
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              {agentName ? (
                                <div>
                                  <div className="flex text-xl font-medium">
                                    <p className=" text-gray-700">
                                      {'Agent: '}
                                    </p>
                                    <p className="text-indigo-600">{` ${agentName}`}</p>
                                  </div>
                                </div>
                              ) : (
                                referralName && (
                                  <div>
                                    <div className="flex text-xl font-medium">
                                      <p className=" text-gray-700">
                                        {'Referral: '}
                                      </p>
                                      <p className="text-indigo-600 px-2">{` ${referralName}`}</p>
                                    </div>
                                  </div>
                                )
                              )}
                            </>
                          ) : (
                            <div>
                              <div>
                                <Summary student={profile} />
                              </div>
                              <div className="flex gap-3 mt-2 mb-0 justify-end pr-6">
                                {studentLeadId &&
                                isInternal &&
                                !role.isStudent ? (
                                  <div className="text-sky-800 text-xl font-medium drop-shadow-">
                                    <Button className="bg-gradient-to-r from-cyan-500 via-cyan-700 to-cyan-800 mb-2 hover:bg-cyan-600 text-gray-100 drop-shadow-md hover:shadow-md">
                                      {
                                        <Link
                                          to={`/leads/${studentLeadId}/leadprofile`}
                                        >
                                          Go to Lead Profile
                                        </Link>
                                      }
                                    </Button>
                                  </div>
                                ) : (
                                  ''
                                )}
                                {!role.isAgent && !role.isStudent && (
                                  <div>
                                    <Button
                                      onClick={() => setAddTask(true)}
                                      startIcon={<AssignmentIcon />}
                                      className="bg-gradient-to-r from-cyan-500 via-cyan-700 to-cyan-800 mb-2 hover:bg-cyan-600 text-gray-100 drop-shadow-md hover:shadow-md"
                                    >
                                      Create Task
                                    </Button>
                                  </div>
                                )}
                                <div>
                                  <Button
                                    onClick={apply}
                                    className="bg-gradient-to-r from-cyan-600 via-cyan-700 to-cyan-800 mb-2 hover:bg-cyan-400 text-gray-100 drop-shadow-md hover:shadow-md"
                                    disabled={
                                      !isInternal && applications.length > 5
                                    }
                                  >
                                    Apply FOR COURSE
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    onClick={() =>
                                      navigate(
                                        `/students/${studentId}/dashboard`
                                      )
                                    }
                                    className="bg-gradient-to-r from-cyan-600 via-cyan-700 to-cyan-800 mb-2 hover:bg-cyan-600 text-gray-100 drop-shadow-md hover:shadow-md"
                                  >
                                    Go to Student Dashboard
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                          <div className="flex gap-3">
                            <div className=" rounded-lg text-xl font-semibold">
                              <section className=" flex gap-6">
                                <div
                                  className={`bg-gray-300 min-h-screen ${
                                    open ? 'w-72' : 'w-16'
                                  } duration-500 text-gray-700 px-3`}
                                >
                                  <div className="py-3 flex justify-end">
                                    <HiMenuAlt3
                                      size={26}
                                      className="cursor-pointer"
                                      onClick={() => setOpen(!open)}
                                    />
                                  </div>
                                  <div className="flex flex-col gap-4 relative">
                                    <Tabs
                                      // TabIndicatorProps={{ style: { display: 'none' } }}
                                      value={tabValue}
                                      onChange={handleTabChange}
                                      orientation="vertical"
                                      variant="scrollable"
                                      aria-label="Vertical tabs"
                                      className=" flex justify-start items-start text-left"
                                    >
                                      <Tab
                                        value="profile"
                                        style={{ textAlign: 'left' }}
                                        {...a11yProps('profile')}
                                        label={
                                          <MyTabLabel
                                            iconPosition="start"
                                            icon={<PersonSearchRounded />}
                                            label="General Info"
                                            showWarning={profileError}
                                          />
                                        }
                                      />
                                      {permissions.CanAccessStudentContact && (
                                        <Tab
                                          value="contactinfo"
                                          style={{ textAlign: 'left' }}
                                          {...a11yProps('contactinfo')}
                                          label={
                                            <MyTabLabel
                                              iconPosition="start"
                                              icon={<ContactsRounded />}
                                              label="Contact Info"
                                              showWarning={contactError}
                                            />
                                          }
                                        />
                                      )}
                                      <Tab
                                        value="edu"
                                        style={{ textAlign: 'left' }}
                                        {...a11yProps('edu')}
                                        label={
                                          <MyTabLabel
                                            iconPosition="start"
                                            icon={<School />}
                                            label={`Education ${
                                              eduRecords.length > 0
                                                ? ' (' + eduRecords.length + ')'
                                                : ''
                                            }`}
                                            showWarning={eduError}
                                          />
                                        }
                                      />
                                      <Tab
                                        value="lang"
                                        style={{ textAlign: 'left' }}
                                        {...a11yProps('lang')}
                                        label={
                                          <MyTabLabel
                                            iconPosition="start"
                                            icon={<LanguageRounded />}
                                            label={`Language ${
                                              langRecords.length > 0
                                                ? ' (' +
                                                  langRecords.length +
                                                  ')'
                                                : ''
                                            }`}
                                            showWarning={false}
                                          />
                                        }
                                      />
                                      <Tab
                                        value="doc"
                                        style={{ textAlign: 'left' }}
                                        {...a11yProps('doc')}
                                        label={
                                          <MyTabLabel
                                            iconPosition="start"
                                            icon={<Description />}
                                            label={`Documents ${
                                              cloudFiles.length > 0
                                                ? ' (' + cloudFiles.length + ')'
                                                : ''
                                            }`}
                                            showWarning={docError}
                                          />
                                        }
                                      />
                                      <Tab
                                        style={{ textAlign: 'left' }}
                                        className="w-64 "
                                        value="pref"
                                        {...a11yProps('pref')}
                                        label={
                                          <MyTabLabel
                                            iconPosition="start"
                                            icon={<AccountBalance />}
                                            label="Preferences"
                                            showWarning={false}
                                          />
                                        }
                                      />
                                      {profile.id > 0 && (
                                        <Tab
                                          value="msg"
                                          style={{ textAlign: 'left' }}
                                          {...a11yProps('msg')}
                                          label={
                                            <MyTabLabel
                                              iconPosition="start"
                                              icon={<Email />}
                                              label="Email"
                                              showWarning={false}
                                            />
                                          }
                                        />
                                      )}
                                      {applications.length > 0 && (
                                        <Tab
                                          style={{ textAlign: 'left' }}
                                          value="app"
                                          {...a11yProps('app')}
                                          label={
                                            <MyTabLabel
                                              iconPosition="start"
                                              icon={<AssignmentOutlined />}
                                              label={`Applications (${applications.length})`}
                                              showWarning={false}
                                            />
                                          }
                                        />
                                      )}
                                      {role.isStudent
                                        ? ''
                                        : isInternal &&
                                          profile.id > 0 && (
                                            <Tab
                                              value="sum"
                                              style={{ textAlign: 'left' }}
                                              {...a11yProps('sum')}
                                              label={
                                                <MyTabLabel
                                                  iconPosition="start"
                                                  icon={<SummarizeRounded />}
                                                  label="Notes"
                                                  showWarning={false}
                                                />
                                              }
                                            />
                                          )}
                                      {role.isStudent
                                        ? ''
                                        : isInternal &&
                                          profile.id > 0 && (
                                            <Tab
                                              style={{ textAlign: 'left' }}
                                              value="tasks"
                                              {...a11yProps('tasks')}
                                              label={
                                                <MyTabLabel
                                                  iconPosition="start"
                                                  icon={<TaskRounded />}
                                                  label="Tasks"
                                                  showWarning={false}
                                                />
                                              }
                                            />
                                          )}

                                      {/* {(permissions.CanManageStudentChatSection)  && (
                                            <Tab
                                              style={{ textAlign: 'left' }}
                                              value="chat"
                                              {...a11yProps('chat')}
                                              label={
                                                <MyTabLabel
                                                  iconPosition="start"
                                                  icon={<ChatRounded />}
                                                  label="Chats"
                                                  showWarning={false}
                                                />
                                              }
                                            />
                                          )} */}

                                      {/* {permissions.CanManageStudentChatSection &&
                                        !profile.agentId && (
                                          <Tab
                                            value="chat"
                                            style={{ textAlign: 'left' }}
                                            {...a11yProps('chat')}
                                            label={
                                              <MyTabLabel
                                                iconPosition="start"
                                                icon={<ChatRounded />}
                                                label="Chats"
                                                showWarning={false}
                                              />
                                            }
                                          />
                                        )} */}

                                      {permissions.CanManageStudentAccountsNote && (
                                        <Tab
                                          style={{ textAlign: 'left' }}
                                          value="accountsnote"
                                          {...a11yProps('accountsnote')}
                                          label={
                                            <MyTabLabel
                                              iconPosition="start"
                                              icon={<CurrencyExchange />}
                                              label="Accounts Note"
                                              showWarning={false}
                                            />
                                          }
                                        />
                                      )}

                                      {role.activities
                                        ? ''
                                        : role.isSuperAdmin && (
                                            <Tab
                                              style={{ textAlign: 'left' }}
                                              value="activities"
                                              {...a11yProps('activities')}
                                              label={
                                                <MyTabLabel
                                                  iconPosition="start"
                                                  icon={<SecurityRounded />}
                                                  label="Activities"
                                                  showWarning={false}
                                                />
                                              }
                                            />
                                          )}
                                    </Tabs>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="w-full rounded-lg bg-gray-300">
                              <TabPanel
                                value={tabValue}
                                index={'profile'}
                                style={{ paddingTop: 0 }}
                              >
                                <StudentProfile
                                  profile={profile}
                                  displayError={profileError}
                                  updateProfileParent={updateProfileParent}
                                  studentPhoto={studentPhoto}
                                  setStudentPhoto={setStudentPhoto}
                                  tempImageParent={tempImage}
                                  setTempImageParent={setTempImage}
                                  inValidEmail={inValidEmail}
                                  setInValidEmail={setInValidEmail}
                                  setTermsAgreed={setTermsAgreed}
                                  termsAgreed={termsAgreed}
                                  name={name}
                                  role={role}
                                  isInternal={isInternal}
                                />
                              </TabPanel>
                              <TabPanel
                                value={tabValue}
                                index={'contactinfo'}
                                style={{ paddingTop: 0 }}
                              >
                                <StudentContactInfo
                                  profile={profile}
                                  handleUpdateInfo={handleUpdateInfo}
                                  formData={formData}
                                  setFormData={setFormData}
                                />
                              </TabPanel>
                              <TabPanel
                                value={tabValue}
                                index={'edu'}
                                style={{ marginTop: 15 }}
                              >
                                <Education
                                  id={profile.id}
                                  setStudentIdParent={setStudentIdParent}
                                  records={eduRecords}
                                  displayError={eduError}
                                  setRecordsGrandParent={setEduRecords}
                                  isLocked={
                                    role.isStudent && profile.profileComplete
                                  }
                                />
                              </TabPanel>
                              <TabPanel
                                value={tabValue}
                                index={'lang'}
                                style={{ marginTop: 15 }}
                              >
                                <Language
                                  id={profile.id}
                                  setStudentIdParent={setStudentIdParent}
                                  records={langRecords}
                                  setRecordsGrandParent={setLangRecords}
                                  isLocked={
                                    role.isStudent && profile.profileComplete
                                  }
                                />
                              </TabPanel>
                              <TabPanel value={tabValue} index={'doc'}>
                                <Documents
                                  id={profile.id}
                                  profileCompleteStatus={
                                    profile.profileComplete
                                  }
                                  setStudentIdParent={setStudentIdParent}
                                  appUserId={appUserId}
                                  roleName={roleName}
                                  cloudFiles={cloudFiles}
                                  displayError={docError}
                                  setCloudFiles={setCloudFiles}
                                  isLocked={
                                    role.isStudent && profile.profileComplete
                                  }
                                  isInternal={isInternal}
                                />
                              </TabPanel>
                              <TabPanel value={tabValue} index={'pref'}>
                                <div>
                                  <Preferences id={profile.id || 0} />
                                </div>
                              </TabPanel>
                              {/* {profile.agentName && ( */}
                              <TabPanel value={tabValue} index={'msg'}>
                                <Messages
                                  studentId={profile.id}
                                  studentName={`${profile.firstName} ${profile.lastName}`}
                                  name={name}
                                  isInternal={isInternal}
                                  records={notes}
                                  setRecords={setNotes}
                                  draftMessageParent={draftMessage}
                                  setDraftMessageParent={setDraftMessage}
                                  agentName={profile.agentName}
                                  role={role}
                                />
                              </TabPanel>

                              {applications.length > 0 && (
                                <TabPanel value={tabValue} index={'app'}>
                                  <ApplicationStage
                                    records={applications}
                                    setReload={setReload}
                                    applicationToExpand={
                                      applicationid ? applicationid : 0
                                    }
                                    isLocked={
                                      !isInternal && profile.profileComplete
                                    }
                                  />
                                </TabPanel>
                              )}

                              {isInternal && profile.id > 0 && (
                                <TabPanel value={tabValue} index={'sum'}>
                                  <SummaryStage
                                    records={notes}
                                    setRecords={setNotes}
                                    studentId={profile.id}
                                    name={name}
                                  />
                                </TabPanel>
                              )}

                              {isInternal && profile.id > 0 && (
                                <TabPanel value={tabValue} index={'tasks'}>
                                  <Tasks
                                    records={notes}
                                    setRecords={setNotes}
                                    studentId={profile.id}
                                    name={name}
                                    taskFor={{
                                      appUserId: profile.appUserId,
                                      name: `${profile.firstName} ${profile.lastName}`,
                                    }}
                                    journey="student"
                                  />
                                </TabPanel>
                              )}

                              {isInternal &&
                                (!profile.agentName ||
                                  profile.agentName.length == 0) && (
                                  <TabPanel value={tabValue} index={'chat'}>
                                    <Chat
                                      recordId={profile.id}
                                      recipientName={`${profile.firstName} ${profile.lastName}`}
                                      name={name}
                                      phone={profile.phoneNumber}
                                      appUserId={appUserId}
                                      type="student"
                                      country={
                                        profile.countryOfResidence ||
                                        profile.citizenship ||
                                        profile.country
                                      }
                                    />
                                  </TabPanel>
                                )}

                              {isInternal && (
                                <TabPanel value={tabValue} index={'activities'}>
                                  <StudentActivites studentId={profile.id} />
                                </TabPanel>
                              )}

                              {isInternal && (
                                <TabPanel
                                  value={tabValue}
                                  index={'accountsnote'}
                                >
                                  <StudentAccountsNote
                                    records={accountsNotes}
                                    setRecords={setStudentAccountsNote}
                                    studentId={profile.id}
                                    name={name}
                                  />
                                </TabPanel>
                              )}
                            </div>
                          </div>
                          <div className="flex justify-end pt-3 pb-6">
                            {role.isAgent ? (
                              <>
                                {' '}
                                {tabValue == 'profile' &&
                                  loaded &&
                                  (!profile.profileComplete || isInternal) && (
                                    <Fab
                                      size="small"
                                      variant="extended"
                                      className="bg-sky-500 hover:bg-sky-400 text-white"
                                      style={{ marginRight: 10 }}
                                      onClick={() => handleSave(false)}
                                      // disabled={
                                      //   profile.firstName.trim().length == 0 ||
                                      //   profile.lastName.trim().length == 0 ||
                                      //   updating ||
                                      //   inValidEmail
                                      // }
                                    >
                                      <DoneIcon />
                                      {updating && (
                                        <>
                                          <CircularProgress size={14} />
                                          <span style={{ marginLeft: 5 }}>
                                            Saving
                                          </span>
                                        </>
                                      )}
                                      {!updating && 'Save'}
                                    </Fab>
                                  )}{' '}
                              </>
                            ) : (
                              <>
                                {tabValue == 'profile' &&
                                  loaded &&
                                  (!profile.profileComplete || isInternal) && (
                                    <Fab
                                      size="small"
                                      variant="extended"
                                      className="bg-sky-500 hover:bg-sky-400 text-white"
                                      style={{ marginRight: 10 }}
                                      onClick={() => handleSave(false)}
                                      // disabled={
                                      //   profile.firstName.trim().length == 0 ||
                                      //   profile.lastName.trim().length == 0 ||
                                      //   updating ||
                                      //   inValidEmail
                                      // }
                                    >
                                      <DoneIcon />
                                      {updating && (
                                        <>
                                          <CircularProgress size={14} />
                                          <span style={{ marginLeft: 5 }}>
                                            Saving
                                          </span>
                                        </>
                                      )}
                                      {!updating && 'Save'}
                                    </Fab>
                                  )}
                              </>
                            )}

                            {(tabValue == 'profile' ||
                              tabValue == 'edu' ||
                              tabValue == 'lang' ||
                              tabValue == 'contactinfo' ||
                              tabValue == 'doc') &&
                              loaded &&
                              !profile.profileComplete && (
                                <Fab
                                  size="small"
                                  variant="extended"
                                  className="bg-violet-500 hover:bg-violet-600 text-white"
                                  disabled={
                                    profile.firstName.trim().length == 0 ||
                                    profile.lastName.trim().length == 0 ||
                                    updating ||
                                    inValidEmail
                                  }
                                  onClick={handleSubmit}
                                >
                                  <TelegramIcon />
                                  {submitting && (
                                    <>
                                      <CircularProgress
                                        size={14}
                                        color="secondary"
                                      />
                                      <span style={{ marginLeft: 5 }}>
                                        Submitting
                                      </span>
                                    </>
                                  )}
                                  {!submitting && 'Submit'}
                                </Fab>
                              )}
                          </div>
                        </div>
                      )}
                      {(openSuccess || openFailure) && (
                        <MySnackbarAlert
                          opnSuccess={openSuccess}
                          opnFailure={openFailure}
                          message={alertMsg}
                          opnSuccessParent={setOpenSuccess}
                          opnFailureParent={setOpenFailure}
                        />
                      )}

                      <ConfirmationModal
                        open={openConfirm}
                        cancel={setOpenConfirm}
                        confirm={submitProfile}
                        confirmText="Confirm Submission"
                        content="Are you sure that you would like to submit the student profile?"
                        errors={errors}
                      />
                      {openAddMessage && (
                        <AddMessage
                          open={openAddMessage}
                          cancel={setOpenAddMessage}
                          toId={profile.appUserId}
                          agentId={0}
                          toName={`${profile.firstName} ${profile.lastName}`}
                        />
                      )}
                      {addTask && (
                        <Task
                          record={{ id: 0, studentId: profile.id }}
                          setAddMode={setAddTask}
                          taskFor={`${profile.firstName} ${profile.lastName}`}
                          cosmosAdminUsersParent={[]}
                          path={1}
                          tabToReturnTo={'tasks'}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      {size.width <= 844 ? (
        <StudentFooterMob />
      ) : role.isStudent ? (
        <StudentFooter />
      ) : (
        ''
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    appUserId: state.auth.id,
    role: state.auth.role,
    roleName: state.auth.roleName,
    isInternal: state.auth.isInternal,
    name: state.auth.name,
    permissions: state.auth.permissions,
  };
};

export default connect(mapStateToProps, null)(Student);
