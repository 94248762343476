import React from 'react';
import {
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
} from '@mui/icons-material';

const defaultIconValues = {
  size: '25px',
  color: '#1E90FF', // Deep sky blue
};

const socialMediaIcons = [
  <FacebookIcon key={0} style={{ fontSize: defaultIconValues.size, color: defaultIconValues.color }} />,
  <LinkedInIcon key={1} style={{ fontSize: defaultIconValues.size, color: defaultIconValues.color }} />,
  <InstagramIcon key={2} style={{ fontSize: defaultIconValues.size, color: defaultIconValues.color }} />,
  <YouTubeIcon key={3} style={{ fontSize: defaultIconValues.size, color: defaultIconValues.color }} />,
];

export const socialMedia = [
  {
    id: 0,
    href: 'https://www.facebook.com/profile.php?id=61559410351550',
    icon: socialMediaIcons[0],
  },
  {
    id: 1,
    href: 'https://www.linkedin.com/company/102890417/admin/feed/posts/',
    icon: socialMediaIcons[1],
  },
  {
    id: 2,
    href: 'https://www.youtube.com/@OldPortsmouthCollege',
    icon: socialMediaIcons[3],
  },
  {
    id: 3,
    href: 'https://www.instagram.com/oldportsmouthcollege/?next=%2F',
    icon: socialMediaIcons[2],
  },
];

export const authStyles = {
  root: {
    width: '100%',
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'linear-gradient(135deg, #FFE4B5 0%, #87CEEB 100%)', // Light orange to deep sky blue gradient
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '400px',
    width: '100%',
    padding: '2rem',
    borderRadius: '12px',
    background: 'rgba(255, 255, 255, 0.9)',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.2)',
  },
  logo: {
    width: '128px',
    height: 'auto',
    marginBottom: '2rem',
  },
  socialMedia: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '2rem',
  },
  socialIcon: {
    margin: '0 12px',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.1)',
    },
  },
  btn: {
    resetPassword: {
      marginTop: '1rem',
      color: '#1E90FF',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
      fontSize: '0.9rem',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  signUpText: {
    marginTop: '1.5rem',
    textAlign: 'center',
    fontSize: '0.9rem',
    color: '#4A5568',
  },
  signUpLink: {
    color: '#1E90FF',
    textDecoration: 'none',
    fontWeight: '600',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
};

export default authStyles;

